
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import RequestsLists from "@/components/mice/requests/List.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
  name: "price-lists-settings",
  components: {
    RequestsLists,
  },
  props:{
  },
  data: function () {
    return {
      myLoading: false,
    }
  },
  methods: {},
  watch: {

  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Gelen Talep", ["MICE", "Gelen Talep"]);
    });





    store.dispatch(Actions.MICE_REQUESTS_LIST, {});
    const myList = computed(() => {
      return store.getters.miceRequestsList;
    });
    return {
      myList,
    };
  },
});



import {computed, defineComponent, ref} from "vue";
import store from "@/store";

import Swal from "sweetalert2/dist/sweetalert2.js";
import RequestsAddNew from "@/views/apps/sys/mice/requests/RequestAddNew.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";

export default defineComponent({
  name: "hotel-list",
  components: {
    RequestsAddNew,
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title', 'AccountTitle'],
      filterFieldName: ['Talep', 'Hesap'],
    }
  },
  props: {
    widgetClasses: String,
    tableData: Object,
  },
  computed: {

    filteredTableList: function () {
      if (this.tableData !== undefined){
        return this.tableData.filter(filteredList =>
            (filteredList.Title !== undefined && !(filteredList.Title).toLowerCase().indexOf(this.searchName.toLowerCase())) ||
            (filteredList.AccountTitle !== undefined && !(filteredList.AccountTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))
        );
      }else{
        return [];
      }
    },

  },
  methods: {

  },
  setup() {


    return {
      dateConvert,
    }
  },
});


import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr";
import Tagify from '@yaireo/tagify';
import priceListsCategory from "@/core/data/pricelistscategory";
import miceSectorList from "@/core/data/micesector";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import {toJSON} from "@/assets/ts/_utils";
import {event} from "maska/types/utils";





interface newRequestData {
  reqTitle: string;
  reqDate: string;
  reqSNG: string;
  reqDBL: string;
  reqTRP: string;
  reqPAX: string;
  reqSMPerson: string;
  reqSector: string;
  reqAccount: string;
  reqCompany: string;
  reqDatesCIN: Array<any>;
  reqDatesCOUT: Array<any>;
  reqHotels: string;
  reqNotes: string;

}
export default defineComponent({
  name: "new-price-list-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      dateCount: 1,
    }
  },
  mounted() {
    this.datapicker();
    return true;
  },
  methods:{
    datapicker() {
      var d = new Date();
      d.setDate(d.getDate()-MasterConfig.mice.requestAddDay);
      let config = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        minDate: d,
        maxDate:'today',
      };
      this.startpicker = flatpickr("#kt_datepicker_1", config);
    },
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newPriceListModalRef = ref<null | HTMLElement>(null);
    const listActive = ref(null)


    const newRequestData = ref<newRequestData>({
      reqTitle: "",
      reqDate: "",
      reqSNG: "",
      reqDBL: "",
      reqTRP: "",
      reqPAX: "",
      reqSMPerson: "",
      reqSector: "",
      reqAccount: "",
      reqCompany: "",
      reqDatesCIN: [],
      reqDatesCOUT: [],
      reqHotels: "",
      reqNotes: "",
    });

    const validationSchema = Yup.object().shape({
      reqTitle: Yup.string().required().label("Fiyat Listesi İsmi"),
    });

    const submit = () => {

      if (!submitButtonRef.value) {
        return;
      }
      let myAccount = getAccount('itemAccount');
      if (!myAccount) {
        Swal.fire({
          text: "Lütfen 'Hesap' bilgisini giriniz",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let myCompany = getAccount('itemCompany');
      let myDates = {
        CIN: newRequestData.value.reqDatesCIN.map(dateConvert.method.convertToDateYM),
        COUT: newRequestData.value.reqDatesCOUT.map(dateConvert.method.convertToDateYM)
        };
      const payload1 = {
        Title: newRequestData.value.reqTitle,
        RequestDate: dateConvert.method.flatpickerToDateYM(newRequestData.value.reqDate),
        AccountID: (getAccount('itemAccount'))[0].ID,
        MiceCompanyID: (myCompany) ? myCompany[0].ID : 'NULL',
        Hotels: newRequestData.value.reqHotels,
        Sector: newRequestData.value.reqSector,
        SMPerson: newRequestData.value.reqSMPerson,
        RoomSNG: newRequestData.value.reqSNG,
        RoomDBL: newRequestData.value.reqDBL,
        RoomTRP: newRequestData.value.reqTRP,
        PAX: newRequestData.value.reqPAX,
        Notes: newRequestData.value.reqNotes,
        Dates: JSON.stringify(myDates),
      }
      console.log(payload1);

      store.dispatch(Actions.MICE_REQUESTS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newPriceListModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getMiceRequestsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        // Activate indicator
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };
    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myAccountTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    store.dispatch(Actions.HOTEL_LIST_ALL, {});
    const myHotel = computed(() => {
      return store.getters.hotelList;
    });

    const myTagsHotel = computed(() => {
      if (myHotel.value.data) {
        let tmpList = [];
        for (let i = 0; i < myHotel.value.data.length; i++) {
          tmpList[i] = {
            value: (myHotel.value.data[i].Title).trim(),
            title: (myHotel.value.data[i].Title).trim(),
            type: 'account',
            ID: myHotel.value.data[i].ID,
          };

        }
        setTagInput(tmpList);
        return tmpList;

      } else {
        return [];
      }
    });
    let tagify2;
    const setTagInput = (wList) => {
      let input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
      tagify2 = new Tagify(input, {
        tagTextProp: 'value', // very important since a custom template is used with this property as text
        enforceWhitelist: true,
        duplicates: false,
        skipInvalid: true, // do not remporarily add invalid tags
        whitelist: wList,
        dropdown: {
          searchKeys: ["value"],
          maxItems: 10,
          closeOnSelect: false,
          position: "text",
          enabled: 0, // always opens dropdown when input gets focus
        },
      });
    }
    function onHotelChange(e) {
      if(e.target.value.length>0) {
        newRequestData.value.reqHotels = e.target.value;
      }else{
        newRequestData.value.reqHotels = '';
      }
    }
    const getAccount = (key) => {
      if (myAccountList.value.data != null) {
        let myInput = document.getElementById(key) as HTMLInputElement;
        if (myInput.value != '') {
          return myAccountList.value.data.filter(item =>
              !(item.Title).indexOf(myInput.value)
          );
        } else {
          return false
        }
      } else {
        return false;
      }
    };
    return {
      MasterConfig,
      myAccountTitle,
      priceListsCategory,
      miceSectorList,
      newRequestData,
      listActive,
      validationSchema,
      submit,
      submitButtonRef,
      newPriceListModalRef,
      myTagsHotel,
      onHotelChange,
    };
  },
});
